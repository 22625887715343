// theme.js

// 1. import `extendTheme` function
import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  components: {
      Table: {
          baseStyle: {
              td: {
                  borderColor: '#393939',
              },
          },
      },
  },
});

export default theme;