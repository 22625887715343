import React, { useState, useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Heading, Skeleton, ChakraProvider, useToast, useBreakpointValue, Link, Switch, FormControl, FormLabel, Button } from '@chakra-ui/react';
import { Amplify, API, graphqlOperation } from 'aws-amplify';

// import theme from './theme';

Amplify.configure({
    aws_appsync_graphqlEndpoint: 'https://fdjmzfem5zb2logmvanhtkdoim.appsync-api.us-west-1.amazonaws.com/graphql',
    aws_appsync_region: 'us-west-1',
    aws_appsync_authenticationType: 'API_KEY',
    aws_appsync_apiKey: 'da2-x6wiheh27zbwdmkn5jezpi4xn4'
});

const getTradesThisMonth = `query getTradesThisMonth {
    listTradesThisMonth {
      id
      date
      strategy
      status
      profit
      openTimestamp
      closeTimestamp
      firstCandle
    }
  }`;

const getOpenTrades = `query getOpenTrades {
    listOpenTrades {
      id
      date
      strategy
      status
      profit
      openTimestamp
      closeTimestamp
      firstCandle
    }
  }`;

const getTradePrices = `query GetTradePrices($id: ID!, $strategy: Strategy) {
    getTradePrices(id: $id, strategy: $strategy) {
        price
        timestamp
        spx
        strategy
        type
    }
}`;


function App() {
    const toast = useToast();
    const chartContainerRef = useRef(null);
    const chart = useRef(null);
    const [openTradeList, setOpenTradeList] = useState([]);
    const [tradeList, setTradeList] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [chartDataSpx, setChartDataSpx] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showChartData, setShowChartData] = useState(false);
    const [selectedTradeId, setSelectedTradeId] = useState(null);
    const [selectedFirstCandle, setSelectedFirstCandle] = useState(null);
    const [currentStrategy, setCurrentStrategy] = useState('BULLISH');

    const tableSize = useBreakpointValue({ base: 'sm', md: 'md' });

    useEffect(() => {
        const handleResize = () => {
            if (chart.current) {
                chart.current.applyOptions({
                    width: chartContainerRef.current.clientWidth,
                    height: 350,
                });
            }
        };

        if (chartContainerRef.current && chartDataSpx.length > 0) {
            // Clear the existing chart
            if (chart.current) {
                chart.current.remove();
            }

            const chartInstance = createChart(chartContainerRef.current, {
                width: chartContainerRef.current.clientWidth,
                height: 350,
                layout: {
                    background: { color: '#000000' },
                    textColor: '#E0E0E0',
                },
                grid: {
                    vertLines: { color: 'rgba(197, 203, 206, 0.2)' },
                    horzLines: { color: 'rgba(197, 203, 206, 0.2)' },
                },
                rightPriceScale: {
                    visible: showChartData,
                    borderColor: 'rgba(197, 203, 206, 0.8)',
                    autoScale: true,
                    minValue: 0,
                    maxValue: 5.00,
                },
                leftPriceScale: {
                    visible: true,
                    borderColor: 'rgba(197, 203, 206, 0.8)',
                    autoScale: true,
                },
                timeScale: {
                    timeVisible: true,
                    secondsVisible: false,
                },
            });

            chart.current = chartInstance;

            if (showChartData && chartData.length > 0) {
                const lineSeries = chartInstance.addAreaSeries({
                    priceScaleId: 'right',
                    topColor: 'rgba(0, 212, 106, 0.6)',
                    bottomColor: 'rgba(0, 212, 106, 0.1)',
                    lineColor: 'rgba(0, 212, 106, 0.9)',
                    lineWidth: 1,
                });
                lineSeries.setData(chartData);

                const openPrice = chartData[0]?.value || 0;
                const highTargetPrice = 2 * openPrice + openPrice;
                const lowTargetPrice = openPrice - 2 * openPrice;

                const lineWidth = 2;
                const minPriceLine = {
                    price: lowTargetPrice,
                    color: '#ef5350',
                    lineWidth: lineWidth,
                    lineStyle: 2, // LineStyle.Dashed
                    axisLabelVisible: true,
                    title: 'stop loss',
                };
                const avgPriceLine = {
                    price: openPrice,
                    color: 'white',
                    lineWidth: lineWidth,
                    lineStyle: 1, // LineStyle.Dotted
                    axisLabelVisible: true,
                    title: 'open price',
                };
                const maxPriceLine = {
                    price: highTargetPrice,
                    color: '#26a69a',
                    lineWidth: lineWidth,
                    lineStyle: 2, // LineStyle.Dashed
                    axisLabelVisible: true,
                    title: 'exit target',
                };

                lineSeries.createPriceLine(minPriceLine);
                lineSeries.createPriceLine(avgPriceLine);
                lineSeries.createPriceLine(maxPriceLine);
            }

            const lineSeriesSpx = chartInstance.addAreaSeries({
                priceScaleId: 'left',
                topColor: 'rgba(255, 255, 255, 0.2)',
                bottomColor: 'rgba(255, 255, 255, 0.1)',
                lineColor: 'rgba(255, 255, 255, 0.3)',
                lineWidth: 1,
            });
            lineSeriesSpx.setData(chartDataSpx);

            // Calculate the range for SPX data
            const spxValues = chartDataSpx.map(item => item.value);
            const minSpx = Math.min(...spxValues);
            const maxSpx = Math.max(...spxValues);
            const padding = (maxSpx - minSpx) * 0.1; // 10% padding

            // Set the range for the left price scale (SPX)
            chartInstance.priceScale('left').applyOptions({
                autoScale: true,
                minValue: minSpx - padding,
                maxValue: maxSpx + padding,
            });

            if (selectedFirstCandle) {
                const spxLow = selectedFirstCandle.low;
                const spxHigh = selectedFirstCandle.high;

                // Add red area below SPX Low
                const redAreaSeries = chartInstance.addAreaSeries({
                    priceScaleId: 'left',
                    topColor: 'rgba(255, 0, 0, 0.2)',
                    bottomColor: 'rgba(255, 0, 0, 0.2)',
                    lineColor: 'rgba(255, 0, 0, 0)',
                    lineWidth: 0,
                });
                redAreaSeries.setData(chartDataSpx.map(item => ({
                    time: item.time,
                    value: Math.min(item.value, spxLow),
                })));

                // Add SPX Low and High price lines
                const spxMinPriceLine = {
                    price: spxLow,
                    color: '#ef5350',
                    lineWidth: 2,
                    lineStyle: 2, // LineStyle.Dashed
                    axisLabelVisible: true,
                    title: 'SPX Low',
                };
                const spxMaxPriceLine = {
                    price: spxHigh,
                    color: '#26a69a',
                    lineWidth: 2,
                    lineStyle: 2, // LineStyle.Dashed
                    axisLabelVisible: true,
                    title: 'SPX High',
                };

                lineSeriesSpx.createPriceLine(spxMinPriceLine);
                lineSeriesSpx.createPriceLine(spxMaxPriceLine);
            }

            // Calculate the time range
            const allData = showChartData ? [...chartData, ...chartDataSpx] : chartDataSpx;
            const minTime = Math.min(...allData.map(item => item.time));
            const maxTime = Math.max(...allData.map(item => item.time));

            // Calculate the optimal bar spacing
            const totalBars = allData.length;
            const optimalBarSpacing = chartContainerRef.current.clientWidth / totalBars;

            // Set the visible range and bar spacing
            chartInstance.timeScale().applyOptions({
                rightOffset: 0,
                barSpacing: optimalBarSpacing,
                fixLeftEdge: true,
                fixRightEdge: true,
            });

            chartInstance.timeScale().setVisibleRange({
                from: minTime,
                to: maxTime,
            });

            window.addEventListener('resize', handleResize);

        }

        return () => {
            if (chart.current) {
                chart.current.remove();
                chart.current = null;
            }
            window.removeEventListener('resize', handleResize);
        };
    }, [chartData, chartDataSpx, selectedFirstCandle, showChartData, currentStrategy]);

    useEffect(() => {
        const fetchTradesThisMonth = async () => {
            try {
                const data = await API.graphql(graphqlOperation(getTradesThisMonth));
                const items = data.data.listTradesThisMonth;
                const formattedData = items.map(item => ({
                    id: item.id,
                    date: item.date,
                    strategy: item.strategy,
                    status: item.status,
                    profit: item.profit,
                    openTimestamp: item.openTimestamp,
                    closeTimestamp: item.closeTimestamp,
                    firstCandle: JSON.parse(item.firstCandle) // Parse the firstCandle JSON string
                }));
                setTradeList(formattedData);
            } catch (error) {
                console.error('Error fetching trade list: ', error);
            } finally {
                setLoading(false);
            }
        };

        fetchTradesThisMonth();
    }, []);

    useEffect(() => {
        const fetchOpenTrades = async () => {
            try {
                const data = await API.graphql(graphqlOperation(getOpenTrades));
                const items = data.data.listOpenTrades;

                if (items.length === 0) {
                    console.warn('No open trades found.');
                    setOpenTradeList([]);
                    return;
                }

                const formattedData = items.map(item => ({
                    id: item.id,
                    date: item.date,
                    strategy: item.strategy,
                    status: item.status,
                    profit: item.profit,
                    openTimestamp: item.openTimestamp,
                    closeTimestamp: item.closeTimestamp,
                    firstCandle: JSON.parse(item.firstCandle) // Parse the firstCandle JSON string
                }));
                setOpenTradeList(formattedData);

                const tradePricesPromises = formattedData.map(async (trade) => {
                    const response = await API.graphql(graphqlOperation(getTradePrices, { id: trade.id, strategy: trade.strategy }));
                    return response.data.getTradePrices;
                });

                const tradePrices = await Promise.all(tradePricesPromises);

                const combinedData = tradePrices.flat().map(item => ({
                    time: item.timestamp,
                    value: item.price,
                }));

                const combinedDataSpx = tradePrices.flat().map(item => ({
                    time: item.timestamp,
                    value: item.spx,
                }));

                setChartData(combinedData);
                setChartDataSpx(combinedDataSpx);
            } catch (error) {
                console.error('Error fetching open trades: ', error);
            }
        };

        fetchOpenTrades();
    }, []);

    const fetchTradePrices = async (id, strategy) => {
        try {
            const response = await API.graphql(graphqlOperation(getTradePrices, { id: id, strategy: strategy }));
            return response.data.getTradePrices;
        } catch (error) {
            console.error('Error fetching trade data:', error);
            return [];
        }
    };

    const handleReplayButton = async (tradeId) => {
        try {
            setSelectedTradeId(tradeId);
            const items = await fetchTradePrices(tradeId, currentStrategy);
            const itemsSpx = await fetchTradePrices(tradeId, currentStrategy);

            if (items.length === 0 || itemsSpx.length === 0) {
                toast({
                    title: "No data found",
                    description: "No trade data available for the selected trade.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                return;
            }

            const formattedItems = items.map(item => ({
                time: Math.floor(item.timestamp / 1000) - 7 * 3600, // Subtract 7 hours (in seconds)
                value: item.price
            }));

            const formattedItemsSpx = itemsSpx.map(item => ({
                time: Math.floor(item.timestamp / 1000) - 7 * 3600, // Subtract 7 hours (in seconds)
                value: item.spx
            }));

            // Ensure both arrays are unique by time
            const uniqueFormattedItems = Array.from(new Map(formattedItems.map(item => [item.time, item])).values());
            const uniqueFormattedItemsSpx = Array.from(new Map(formattedItemsSpx.map(item => [item.time, item])).values());

            // Find the matching trade in openTradeList
            const matchingTrade = openTradeList.find(trade => trade.id === tradeId);

            if (matchingTrade) {
                setSelectedTradeId(tradeId);
                setSelectedFirstCandle(matchingTrade.firstCandle);
                setChartData(uniqueFormattedItems);
                setChartDataSpx(uniqueFormattedItemsSpx);

                // Scroll to the selected row
                setTimeout(() => {
                    const selectedRow = document.getElementById(`trade-row-${tradeId}`);
                    if (selectedRow) {
                        selectedRow.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                }, 100);

                // Scroll the graph to the left
                if (chart.current) {
                    const timeScale = chart.current.timeScale();
                    timeScale.fitContent();
                    const visibleRange = timeScale.getVisibleRange();
                    const newVisibleRange = {
                        from: visibleRange.from,
                        to: visibleRange.from + (visibleRange.to - visibleRange.from)
                    };
                    timeScale.setVisibleRange(newVisibleRange);
                }
            } else {
                console.log('Matching trade not found');
            }

            // Scroll to the top
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } catch (error) {
            console.error('Error fetching all trade data:', error);
            toast({
                title: "Error",
                description: "An error occurred while fetching trade data.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const toggleStrategy = async () => {
        const newStrategy = currentStrategy === 'BULLISH' ? 'BEARISH' : 'BULLISH';
        setCurrentStrategy(newStrategy);
        
        if (selectedTradeId) {
            await handleReplayButton(selectedTradeId);
        }
    };

    return (
        <ChakraProvider>
        <Box bg="black" color="#E0E0E0" display='flex' alignItems='center' justifyContent='center' width='100%' margin='auto' flexDirection='column' overflowX='hidden'>
            <Heading as="h1" size="2xl" mb={4}>Tradebot</Heading>
            <Heading as="h2" size="md" mb={4}>Trade Replay</Heading>
            
            {/* Add the toggle switch here */}
            <FormControl display="flex" alignItems="center" mb={4}>
                <FormLabel htmlFor="chart-toggle" mb="0">
                    Show Trade Data
                </FormLabel>
                <Switch
                    id="chart-toggle"
                    isChecked={showChartData}
                    onChange={(e) => setShowChartData(e.target.checked)}
                />
            </FormControl>

            <FormControl display="flex" alignItems="center" mb={4}>
                <FormLabel htmlFor="strategy-toggle" mb="0">
                    Strategy: {currentStrategy}
                </FormLabel>
                <Button onClick={toggleStrategy}>
                    Toggle Strategy
                </Button>
            </FormControl>

            <div ref={chartContainerRef} style={{ width: '100%', height: '350px', backgroundColor: '#000000' }}></div>

            <Heading as="h2" size="lg" mt={4} mb={0}>Open Trades</Heading>
            {openTradeList.length === 0 ? (
                <Box mt="4" width="80%" textAlign="center" my="30">
                    <p>No open trades found.</p>
                </Box>
            ) : (
                <Box mt="4" mb="8" width='100%' overflowX="auto">
                    <Table variant="simple" size={tableSize} textAlign='left' mb="4">
                        <Thead>
                            <Tr>
                                <Th></Th>
                                <Th>Trade ID</Th>
                                <Th>Date</Th>
                                <Th>Strategy</Th>
                                <Th>Status</Th>
                                <Th>Profit</Th>
                                <Th>Open Timestamp</Th>
                                <Th>Close Timestamp</Th>
                                <Th>First Candle (High)</Th>
                                <Th>First Candle (Low)</Th>
                                <Th>First Candle (Close)</Th>
                                <Th>First Candle (Open)</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                {openTradeList.map((item, index) => (
                    <Tr 
                        key={index} 
                        bg={item.id === selectedTradeId ? "gray.700" : "inherit"}
                        id={`trade-row-${item.id}`}
                    >
                        <Td>
                            <Link color="white" fontWeight="bold" onClick={() => handleReplayButton(item.id)}>
                                Replay
                            </Link>
                        </Td>
                        <Td>{item.id}</Td>
                        <Td>{new Date(item.date).toLocaleDateString("en-US")}</Td>
                        <Td>{item.strategy}</Td>
                        <Td>{item.status}</Td>
                        <Td>{item.profit}</Td>
                        <Td>{item.openTimestamp}</Td>
                        <Td>{item.closeTimestamp}</Td>
                        <Td>{item.firstCandle.high}</Td>
                        <Td>{item.firstCandle.low}</Td>
                        <Td>{item.firstCandle.close}</Td>
                        <Td>{item.firstCandle.open}</Td>
                    </Tr>
                ))}
            </Tbody>
                    </Table>
                </Box>
            )}

            <Heading as="h2" size="lg" mt={4} mb={0}>Past Trades</Heading>
            {loading ? (
                <Box width='100%'>
                    <Skeleton height="50px" mb="2" />
                    <Skeleton height="50px" mb="2" />
                    <Skeleton height="50px" mb="2" />
                    <Skeleton height="50px" mb="2" />
                    <Skeleton height="50px" mb="2" />
                </Box>
            ) : (
                <Box mt="4" mb="8"  width='100%' overflowX="auto">
                    <Table variant="simple" size={tableSize} textAlign='left' mb="4">
                        <Thead>
                            <Tr>
                                <Th></Th>
                                <Th>Trade ID</Th>
                                <Th>Date</Th>
                                <Th>Strategy</Th>
                                <Th>Status</Th>
                                <Th>Profit</Th>
                                <Th>Open Timestamp</Th>
                                <Th>Close Timestamp</Th>
                                <Th>First Candle (High)</Th>
                                <Th>First Candle (Low)</Th>
                                <Th>First Candle (Close)</Th>
                                <Th>First Candle (Open)</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {tradeList.map((item, index) => (
                                <Tr 
                                    key={index} 
                                    bg={item.id === selectedTradeId ? "gray.700" : "inherit"}
                                    id={`trade-row-${item.id}`}
                                >
                                    <Td>
                                            <Link color="white" fontWeight="bold" onClick={() => handleReplayButton(item.id)}>
                                                Replay
                                            </Link>
                                        </Td>
                                    <Td>{item.id}</Td>
                                    <Td>{new Date(item.date).toLocaleDateString("en-US")}</Td>
                                    <Td>{item.strategy}</Td>
                                    <Td>{item.status}</Td>
                                    <Td>{item.profit}</Td>
                                    <Td>{item.openTimestamp}</Td>
                                    <Td>{item.closeTimestamp}</Td>
                                    <Td>{item.firstCandle.high}</Td>
                                    <Td>{item.firstCandle.low}</Td>
                                    <Td>{item.firstCandle.close}</Td>
                                    <Td>{item.firstCandle.open}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
            )}
        </Box>
        </ChakraProvider>
    );
}

export default App;